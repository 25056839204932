
import { Navbar, Container, Nav, Form, Button } from "react-bootstrap";
import { UserContext } from '../../hooks/useUser';
import { useContext } from "react";

function AdminNav(props) {

    const { onSearchChange } = props;
    const { logoutUser, userDetails } = useContext(UserContext);

    return (
        <>
        <Navbar fixed="top" className="d-flex" style={{backgroundColor: "black"}}>
            <Container>
                <Navbar.Text style={{color: "white"}}>{userDetails.firstName} {userDetails.lastName}</Navbar.Text>
                <Navbar.Brand className="justify-content-center" placement="center">
                    <img
                    alt=""
                    src={process.env.PUBLIC_URL + '/logo192.png'}
                    width="35"
                    height="35"
                    className="d-inline-block align-top"
                    />{' '}
                </Navbar.Brand>
                <Nav className="justify-content-end">
                <Button variant="danger" onClick={logoutUser}>Logout</Button>
                </Nav>
            </Container>
        </Navbar>
        <Navbar sticky="top" style={{backgroundColor: "black", paddingTop: 70}}>
            <Container>
                <Form.Control className="form-control" type="text" id="searchBar" style={{width: '100%'}} placeholder="Search..." onChange={onSearchChange} />
            </Container>
        </Navbar>
        </>
    );
}

export default AdminNav;