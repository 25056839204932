import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { DrinkUpServiceApiContext } from '../DrinkUpServiceApiContext';
import { Paths } from '../pages';

// Authorization Token
export const AUTH_TOKEN_NAME = 'token';
// User Types
export const ADMIN_USER = "ADMIN";
export const MANAGER_USER = "MANAGER";
export const MEMBER_USER = "MEMBER";

const USER_TYPE_MAP = {
    A: ADMIN_USER,
    M: MANAGER_USER,
    U: MEMBER_USER
};

const getAuthToken = () => {
    const tokenString = localStorage.getItem(AUTH_TOKEN_NAME);
    console.log("getting token from session Storage: " + tokenString);

    return tokenString;
}

const mapUserDetails = (userModel) => ({
    firstName: userModel.first_name || '',
    lastName: userModel.last_name || '',
    type: USER_TYPE_MAP[userModel.user_type] || MEMBER_USER,
    rumbustionLevel: userModel.rumbustion_level || 'Initiate'
});


function UserContextProvider(props) {
    const { drinkUpService } = useContext(DrinkUpServiceApiContext);
    const [authToken, setAuthToken] = useState(getAuthToken());
    const [userDetails, setUserDetails] = useState(null);

    const loadedUserDetails = useRef(false);

    console.log("loaded user details: " + loadedUserDetails.current);

    const authorizationHeader = useMemo(() => ({
        Authorization: `Token ${authToken}`
    }), [authToken]);

    const requestUserLogin = useCallback((username, password) => {
        return drinkUpService.post(Paths.api.login, {
            username: username,
            password: password
        })
    }, [drinkUpService]);

    const requestUser = useCallback(() => {
        console.log('loading user details');
        return drinkUpService.get(Paths.api.user, {
            headers: {
                ...authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
    }, [authorizationHeader, drinkUpService]);

    const requestUserLogout = useCallback(() => {
        return drinkUpService.get(Paths.api.logout, {
            headers: authorizationHeader
        });
    }, [authorizationHeader, drinkUpService]);

    const loginUser = useCallback((username, password) => {
        console.log("loggin in");

        requestUserLogin(username, password)
            .then((response) => {
                const authToken = response.data.token;
                setAuthToken(authToken);
                localStorage.setItem(AUTH_TOKEN_NAME, authToken);
            })
            .catch((error) => {
                alert("Failed to login due to error: " + error + ". \n\nPlease try again.");
            });
    }, [requestUserLogin]);

    const logoutUser = useCallback(() => {
        console.log("loggin out");

        requestUserLogout().then(() => {
            localStorage.removeItem("token");
            localStorage.clear();
            setAuthToken(null);
        });
    }, [requestUserLogout, setAuthToken]);

    const loadUserDetails = useCallback(() => {
        requestUser(authToken).then(response => {
            setUserDetails(mapUserDetails(response.data));
            console.log("user fetched: " + JSON.stringify(response.data));
            loadedUserDetails.current = true;
        })
            .catch(err => {
                console.log(err);
                alert("Sorry, an error occurred. Please try again.");
            });
    }, [authToken, requestUser, setUserDetails]);

    useEffect(() => {
        console.log("use effect useUser");
        console.log(`authToken: ${authToken}`)
        if (!loadedUserDetails.current && authToken) {
            loadedUserDetails.current = true;
            loadUserDetails();
        }
    }, [authToken, loadedUserDetails, loadUserDetails]);

    return (
        <UserContext.Provider value={{
            authToken: authToken,
            logoutUser: logoutUser,
            loginUser: loginUser,
            setAuthToken: setAuthToken,
            userDetails: userDetails,
            setUserDetails: setUserDetails,
        }}>
            {props.children}
        </UserContext.Provider>
    )
}

const UserContext = createContext({
    authToken: null,
    logoutUser: null,
    loginUser: null,
    setAuthToken: null,
    userDetails: null,
    setUserDetails: null,
});

function useUser() {
    const { authToken, userDetails } = useContext(UserContext);

    return {
        authToken,
        // firstName
        // lastName
        // emailAddress
        // type (ADMIN | MANAGER | MEMBER)
        ...userDetails
    };
}

export { UserContext, UserContextProvider, useUser };
