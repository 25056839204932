import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Col, Row, Form } from "react-bootstrap";

export default function UserRumModal({ closeModal, show, rum, requestRum, saveNotes}) {    

    if(!rum) {
        return (
            <></>
        )
    }

    function request() {
        const isDelete = "requested_on" in rum ? true : false;
        const isGift = isDelete ? false : document.getElementById("is_gift").checked;
        const giftGiver = isDelete ? null : document.getElementById("gift_giver").value;
        console.log("isDelte: " + isDelete + "isgift: " + isGift + "by" + giftGiver);
        requestRum(rum.id, isDelete, isGift, giftGiver);
    }

    function formattedDateTime(dateString) {
        const date = new Date(Date.parse(dateString));

        var ampm = "AM";

        var hours = date.getHours();
        if(hours > 12) {
            hours = hours - 12;
            ampm = "PM";
        } else if(hours === 0) {
            hours = 12;
        }

        return date.toDateString() + ", " + hours + ":" + String(date.getMinutes()).padStart(2, '0') + " " + ampm;
    }

    function sendNotes() {
        saveNotes(document.getElementById('notesArea').value);
    }

    return (
        <>
        <Modal show={show} onHide={closeModal} size="lg">
            <Modal.Header closeButton>
            <Modal.Title>{rum != null ? rum.bevager_name : ""}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12} sm={3} className="text-sm-end text-xs-start">
                    <label className="fw-bold">Country</label>
                    </Col>
                    <Col xs={12} sm={8}>
                    {rum != null ? rum.origin_country : ""}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={12} sm={3} className="text-sm-end text-xs-start">
                    <label className="fw-bold">Price</label>
                    </Col>
                    <Col xs={12} sm={8}>
                    {rum != null ? "$" + parseFloat(rum.price).toFixed(0) : ""}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={12} sm={3} className="text-sm-end text-xs-start">
                        <label className="fw-bold">Request Status</label>
                    </Col>
                    <Col xs={12} sm={8}>
                    {
                        rum && "approver" in rum
                        ? "Approved by " + rum.approver + " on " + formattedDateTime(rum.requested_on)
                        : rum && "requested_on" in rum
                        ? <div>Requested {formattedDateTime(rum.requested_on)}<br /><Button style={{padding: 0}} variant="link" onClick={request}>Cancel Request</Button></div>
                        : <><Button style={{padding: 0}} variant="link" onClick={request}>Request</Button><Form.Check type="checkbox" label="Is Gift" id='is_gift' /><Form.Control type="text" id="gift_giver" placeholder="Gifted By" /></>
                    }
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={12} sm={3} className="text-sm-end text-xs-start">
                        <label className="fw-bold">Notes</label>
                    </Col>
                    <Col xs={12} sm={8}>
                        <textarea id="notesArea" defaultValue={"notes" in rum ? rum.notes : ""}>
                        </textarea>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={sendNotes}>
                    Save Notes
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}
