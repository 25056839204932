
import { Container, Spinner, Row, Col } from "react-bootstrap";

function LoadingScreen() {
    return (
        <Container fluid data-bs-theme="dark" data-theme="dark" className="text-light" id="body">
            <Row className="justify-content-center align-items-center align-self-center mt-4">
                <Col>
                    <div className="d-flex align-items-center align-self-center justify-content-center">
                        <Spinner animation="border" variant="primary" />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default LoadingScreen;