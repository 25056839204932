import { Modal, Form } from "react-bootstrap";

function UserSortModal(props) {

    const { closeModal, show, sortOptions, currentSort, onSortChange } = props;

    return (
        <>
        <Modal show={show} onHide={closeModal} size="lg">
            <Modal.Header closeButton>
            <Modal.Title>Sort Options</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
            {Object.values(sortOptions).map(sortOption => 
                <Form.Check
                type="radio"
                name="group"
                id={`radio-id-${sortOption}`}
                checked={sortOption === currentSort}
                label={sortOption}
                style={{paddingBottom: 20}}
                value={sortOption}
                key={sortOption}
                onChange={onSortChange}
              />
            )}
            </Form>
            </Modal.Body>
        </Modal>
        </>
    );
}

export default UserSortModal;