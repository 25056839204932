
import { Navbar, Container, Nav, Form } from "react-bootstrap";
import { PersonCircle, Sliders, Dice6Fill, SortUp } from "react-bootstrap-icons";

function UserNav(props) {

    const { onDiceSelect, onSearchChange, onProfileSelect, onSortSelect, onFilterSelect } = props;

    return (
        <>
        <Navbar fixed="top" className="d-flex" style={{backgroundColor: "black"}}>
            <Container style={{color: "white !important"}}>
                <Nav className="">
                    <Nav.Link onClick={onProfileSelect}><PersonCircle color="white" size={22} /></Nav.Link>
                    <Nav.Link onClick={onDiceSelect}><Dice6Fill color="white" size={22} /></Nav.Link>
                </Nav>
                <Navbar.Brand className="justify-content-center" placement="center">
                    <img
                    alt=""
                    src={process.env.PUBLIC_URL + '/logo192.png'}
                    width="35"
                    height="35"
                    className="d-inline-block align-top"
                    />{' '}
                </Navbar.Brand>
                <Nav className="justify-content-end">
                    <Nav.Link onClick={onSortSelect}><SortUp color="white" size={22} /></Nav.Link>
                    <Nav.Link onClick={onFilterSelect}><Sliders color="white" size={17} /></Nav.Link>
                </Nav>
            </Container>
        </Navbar>
        <Navbar sticky="top" style={{backgroundColor: "black", paddingTop: 70}}>
            <Container>
                <Form.Control className="form-control" type="text" id="searchBar" style={{width: '100%'}} placeholder="Search..." onChange={onSearchChange} />
            </Container>
        </Navbar>
        </>
    );
}

export default UserNav;