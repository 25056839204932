const Paths = {
    admin: "/admin",
    login: "/login",
    migrate: "/migrate",
    create: "/create",
    root: "/",
    rums: "/rums",
    api: {
        user: "/get-user",
        login: "/api-token-auth",
        logout: '/get-user',
        migrate: '/migrate-user',
        create: '/create-user'
    }
};

export default Paths;
