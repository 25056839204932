import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';

import { DrinkUpServiceApiProvider } from './DrinkUpServiceApiProvider';
import { AdminPage, LoginPage, MigrationPage, Paths, RootPage, UserPage } from './pages';
import { UserContextProvider } from './hooks/useUser';
import { CreateUserPage } from './pages/CreateUserPage';

document.title = "Rumbustible";

function App() {

  return (
    <div>
      <DrinkUpServiceApiProvider>
        <UserContextProvider>
          <BrowserRouter>
            <Routes>
              <Route
                path={Paths.root}
                element={<RootPage />}
              />
              <Route
                path={Paths.login}
                element={<LoginPage />}
              />
              <Route
                path={Paths.rums}
                element={<UserPage />}
              />
              <Route
                path={Paths.admin}
                element={<AdminPage />}
              />
              <Route
                path={Paths.migrate}
                element={<MigrationPage />}
              />
              <Route
                path={Paths.create}
                element={<CreateUserPage />}
              />
            </Routes>
          </BrowserRouter>
        </UserContextProvider>
      </DrinkUpServiceApiProvider>
    </div>
  );
}

export default App;
