import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Col, Row, Form } from "react-bootstrap";

const countryChoices = [
    "Antigua and Barbuda","Australia","Austria", "Bahamas", "Barbados", 
    "Belize", "Bermuda", "Brazil", "British Virgin Islands", "Cambodia",
    "Canada", "Cape Verde", "Cayman Islands", "Colombia", "Costa Rica", 
    "Cuba", "Denmark", "Dominican Republic","Earth", "Ecuador", 
    "El Salvador", "Fiji", "Grenada", 
    "Guadeloupe", "Guatemala", "Guyana", "Haiti", 
    "India", "Indonesia", "Jamaica", "Japan", "Madagascar", 
    "Marie-Galante", "Martinique", "Mauritius", "Mexico", 
    "N/A", "Navy Style", "Nepal", "New Zealand", 
    "Nicaragua", "Panama", "Paraguay", "Peru", "Philippines", "Portugal", 
    "Puerto Rico", "Reunion", "Royal Navy Rum", "Saint Kitts and Nevis", "Saint Lucia", 
    "Saint Vincent and the Grenadines", "Seychelles", "South Africa", 
    "Spain", "Sri Lanka", "Tahiti", "Thailand", "Trinidad and Tobago", 
    "Turks and Caicos Islands","United Kingdom", "United States",
    "Venezuela", "Virgin Islands", "Regional Blend"
];

const levelOptions = [
    "Any Level",
    "Initiate",
    "Disciple",
    "Guardian",
    "Master",
    "Black Tassel",
    "Legend",
    "Constable"
];

const stateChoices = [
    ['AK', 'Alaska'],
    ['AL', 'Alabama'],
    ['AR', 'Arkansas'],
    ['AZ', 'Arizona'],
    ['CA', 'California'],
    ['CO', 'Colorado'],
    ['CT', 'Connecticut'],
    ['DC', 'District of Columbia'],
    ['DE', 'Delaware'],
    ['FL', 'Florida'],
    ['GA', 'Georgia'],
    ['HI', 'Hawaii'],
    ['IA', 'Iowa'],
    ['ID', 'Idaho'],
    ['IL', 'Illinois'],
    ['IN', 'Indiana'],
    ['KS', 'Kansas'],
    ['KY', 'Kentucky'],
    ['LA', 'Louisiana'],
    ['MA', 'Massachusetts'],
    ['MD', 'Maryland'],
    ['ME', 'Maine'],
    ['MI', 'Michigan'],
    ['MN', 'Minnesota'],
    ['MO', 'Missouri'],
    ['MS', 'Mississippi'],
    ['MT', 'Montana'],
    ['NC', 'North Carolina'],
    ['ND', 'North Dakota'],
    ['NE', 'Nebraska'],
    ['NH', 'New Hampshire'],
    ['NJ', 'New Jersey'],
    ['NM', 'New Mexico'],
    ['NV', 'Nevada'],
    ['NY', 'New York'],
    ['OH', 'Ohio'],
    ['OK', 'Oklahoma'],
    ['OR', 'Oregon'],
    ['PA', 'Pennsylvania'],
    ['RI', 'Rhode Island'],
    ['SC', 'South Carolina'],
    ['SD', 'South Dakota'],
    ['TN', 'Tennessee'],
    ['TX', 'Texas'],
    ['UT', 'Utah'],
    ['VA', 'Virginia'],
    ['VT', 'Vermont'],
    ['WA', 'Washington'],
    ['WI', 'Wisconsin'],
    ['WV', 'West Virginia'],
    ['WY', 'Wyoming'],
];

export default function AdminRumModel({ closeModal, show, rum, save}) {    

    if(!rum) {
        return (
            <></>
        )
    }

    function saveRum() {

        let rumToUpdate = {
            bevager_name: document.getElementById("bevager_name").value,
            raw_material: document.getElementById("raw_material").value,
            is_independent_bottler: document.getElementById("is_independent_bottler").checked,
            is_event: document.getElementById("is_event").checked,
            is_smugglers_cove_private_bottling: document.getElementById("is_smugglers_cove_private_bottling").checked,
            pour_size: document.getElementById("pour_size").value,
            is_migrated: document.getElementById("is_migrated").checked,
            is_immortal: document.getElementById("is_immortal").checked,
            is_shown: document.getElementById("is_shown").checked,
            is_available: document.getElementById("is_available").checked,
            price: document.getElementById("price").value,
            origin_country: document.getElementById("origin_country").value,
            level_limit: document.getElementById("level_limit").value,
        };

        if(!!rum.id) {
            rumToUpdate.id = rum.id;
        }

        if(!!rum.bevager_id) {
            rumToUpdate.bevager_id = rum.bevager_id;
        }

        //brand name
        const brand_name = document.getElementById("brand_name").value;
        if(brand_name) {
            rumToUpdate.brand_name = brand_name;
        } else {
            rumToUpdate.brand_name = null;
        }
        //priduct name
        const product_name = document.getElementById("product_name").value;
        if(product_name) {
            rumToUpdate.product_name = product_name;
        } else {
            rumToUpdate.product_name = null;
        }
        //us state
        const us_state = document.getElementById("us_state").value;
        if(us_state) {
            rumToUpdate.us_state = us_state;
        } else {
            rumToUpdate.us_state = null;
        }
        //distillery
        const distillery = document.getElementById("distillery").value;
        if(distillery) {
            rumToUpdate.distillery = distillery;
        } else {
            rumToUpdate.distillery = null;
        }
        //cask finish
        const cask_finish = document.getElementById("cask_finish").value;
        if(cask_finish) {
            rumToUpdate.cask_finish = cask_finish;
        } else {
            rumToUpdate.cask_finish = null;
        }
        //year distilled
        const year_distilled = document.getElementById("year_distilled").value;
        if(year_distilled) {
            rumToUpdate.year_distilled = year_distilled;
        } else {
            rumToUpdate.year_distilled = null;
        }
        //year bottled
        const year_bottled = document.getElementById("year_bottled").value;
        if(year_bottled) {
            rumToUpdate.year_bottled = year_bottled;
        } else {
            rumToUpdate.year_bottled = null;
        }
        //age
        const age = document.getElementById("age").value;
        if(age) {
            rumToUpdate.age = age;
        } else {
            rumToUpdate.age = null;
        }
        //marque
        const marque = document.getElementById("marque").value;
        if(marque) {
            rumToUpdate.marque = marque;
        } else {
            rumToUpdate.marque = null;
        }
        //alcohol percentage
        const alcohol_percentage = document.getElementById("alcohol_percentage").value;
        if(alcohol_percentage) {
            rumToUpdate.alcohol_percentage = alcohol_percentage;
        } else {
            rumToUpdate.alcohol_percentage = null;
        }
        //cove notes
        const cove_notes = document.getElementById("cove_notes").value;
        if(cove_notes) {
            rumToUpdate.cove_notes = cove_notes;
        } else {
            rumToUpdate.cove_notes = null;
        }
        //production method
        const production_method = document.getElementById("production_method").value;
        if(production_method) {
            rumToUpdate.production_method = production_method;
        } else {
            rumToUpdate.production_method = null;
        }
        //pour size notes
        const pour_size_notes = document.getElementById("pour_size_notes").value;
        if(pour_size_notes) {
            rumToUpdate.pour_size_notes = pour_size_notes;
        } else {
            rumToUpdate.pour_size_notes = null;
        }

        save(rumToUpdate);
    }

    return (
        <>
        <Modal show={show} onHide={closeModal} size="lg">
            <Modal.Header closeButton>
            <Modal.Title>{rum.bevager_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        Bevager id: {rum.bevager_id}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Label htmlFor="bevager_name">Bevager Name:</Form.Label>
                        <Form.Control
                            type="text"
                            id="bevager_name"
                            defaultValue={rum.bevager_name}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Label htmlFor="brand_name">Brand Name:</Form.Label>
                        <Form.Control
                            type="text"
                            id="brand_name"
                            defaultValue={rum.brand_name}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Label htmlFor="product_name">Product Name:</Form.Label>
                        <Form.Control
                            type="text"
                            id="product_name"
                            defaultValue={rum.product_name}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        Origin Country:
                        <Form.Select id="origin_country" defaultValue={rum.origin_country}>
                            {countryChoices.map( function(country, i) {
                                return (
                                    <option key={i} value={country}>{country}</option>
                                )
                            })}
                        </Form.Select>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        US State:
                        <Form.Select id="us_state" defaultValue={rum.us_state}>
                            <option value="">-----</option>
                            {stateChoices.map( function(stateArray, i) {
                                return (
                                    <option key={i} value={stateArray[0]}>{stateArray[1]}</option>
                                )
                            })}
                        </Form.Select>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Label htmlFor="cask_finish">Cask Finish:</Form.Label>
                        <Form.Control
                            type="text"
                            id="cask_finish"
                            defaultValue={rum.cask_finish}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Label htmlFor="distillery">Distillery:</Form.Label>
                        <Form.Control
                            type="text"
                            id="distillery"
                            defaultValue={rum.distillery}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        Raw Material:
                        <Form.Select id="raw_material" defaultValue={rum.raw_material}>
                            <option value="Molasses">Molasses</option>
                            <option value="Fresh Cane Juice">Fresh Cane Juice</option>
                            <option value="Sugar Cane Syrup">Sugar Cane Syrup</option>
                            <option value="Evaporated Cane Juice">Evaporated Cane Juice</option>
                        </Form.Select>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            label="Is Independent Bottler"
                            id='is_independent_bottler'
                            defaultChecked={rum.is_independent_bottler}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            label="Is Event"
                            id='is_event'
                            defaultChecked={rum.is_event}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            label="Is Cove Private Bottling"
                            id='is_smugglers_cove_private_bottling'
                            defaultChecked={rum.is_smugglers_cove_private_bottling}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Label htmlFor="year_distilled">Year Distilled:</Form.Label>
                        <Form.Control
                            type="number"
                            id="year_distilled"
                            defaultValue={rum.year_distilled}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Label htmlFor="year_bottled">Year Bottled:</Form.Label>
                        <Form.Control
                            type="number"
                            id="year_bottled"
                            defaultValue={rum.year_bottled}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Label htmlFor="age">Age:</Form.Label>
                        <Form.Control
                            type="text"
                            id="age"
                            defaultValue={rum.age}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Label htmlFor="marque">Marque:</Form.Label>
                        <Form.Control
                            type="text"
                            id="marque"
                            defaultValue={rum.marque}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Label htmlFor="alcohol_percentage">Alcohol Percentage:</Form.Label>
                        <Form.Control
                            type="number"
                            id="alcohol_percentage"
                            defaultValue={rum.alcohol_percentage}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Label htmlFor="cove_notes">Cove Notes:</Form.Label>
                        <Form.Control
                            type="text"
                            id="cove_notes"
                            defaultValue={rum.cove_notes}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        Production Method
                        <Form.Select id="production_method" defaultValue={rum.production_method}>
                            <option value="">----</option>
                            <option value="Pot">Pot</option>
                            <option value="Columb">Column</option>
                            <option value="Blend (pot & column)">Blend (pot & column)</option>
                        </Form.Select>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            label="Is Immortal"
                            id='is_immortal'
                            defaultChecked={rum.is_immortal}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Label htmlFor="price">Price:</Form.Label>
                        <Form.Control
                            type="number"
                            id="price"
                            defaultValue={rum.price}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            label="Is Shown"
                            id='is_shown'
                            defaultChecked={rum.is_shown}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            label="Is Available"
                            id='is_available'
                            defaultChecked={rum.is_available}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        Pour Size
                        <Form.Select id="pour_size" defaultValue={rum.pour_size}>
                            <option value="1">1 oz</option>
                            <option value="2">2 oz</option>
                            <option value="other">Other</option>
                        </Form.Select>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Label htmlFor="pour_size_notes">Pour Size Notes:</Form.Label>
                        <Form.Control
                            type="text"
                            id="pour_size_notes"
                            defaultValue={rum.pour_size_notes}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            label="Is Migrated"
                            id='is_migrated'
                            defaultChecked={rum.is_migrated}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        Level Limit:
                        <Form.Select id="level_limit" defaultValue={rum.level_limit}>
                            {levelOptions.map( function(level, i) {
                                return (
                                    <option key={i} value={i}>{level}</option>
                                )
                            })}
                        </Form.Select>
                    </Col>
                </Row>
                <hr />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={saveRum}>
                    { !!rum.id ? "Save Changes" : "Add Rum" }
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}