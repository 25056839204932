import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import LoadingScreen from '../components/LoadingScreen';
import { MEMBER_USER, UserContext } from '../hooks/useUser';
import Paths from './Paths';

/**
 * This page is intended to detect user login status and redirect accordingly
 */
function RootPage() {
    const { authToken, userDetails } = useContext(UserContext);

    if (!authToken) {
        console.log("hello");
        return <Navigate to={Paths.login} replace={true} />;
    }
    if (!userDetails) {
        console.log("showing loading");
        return <LoadingScreen />
    }

    if (userDetails.type === MEMBER_USER) {
        console.log("member user");
        return <Navigate to={Paths.rums} replace={true} />;
    } else {
        return <Navigate to={Paths.admin} replace={true} />;
    }
}

export { RootPage };
