import { useContext } from 'react';

import { DrinkUpServiceApiContext } from '../../DrinkUpServiceApiContext';
import { Paths } from '../../pages';

function useCreateUserFromCraftable() {
    const { drinkUpService } = useContext(DrinkUpServiceApiContext);

    const createUserFromCraftable = async ({ 
        emailAddress, firstName, lastName, craftablePassword, drinkUpPassword
    }) => {
        try {
            const res = await drinkUpService.post(Paths.api.migrate, {
                // TODO Update User Details mapper to include email Address
                email: emailAddress,
                firstName: firstName,
                lastName: lastName,
                craftablePassword: craftablePassword,
                newPassword: drinkUpPassword
            });
            console.log("called craftable migrate response:", res);

            const success = res.status === 201;
            if (success) {
                return success;
            } else {
                return res;
            }
        } catch (e) {
            console.error('failed to createUserFromCraftable', e)
            return e;
        }
    };

    return {
        createUserFromCraftable
    }
}

export { useCreateUserFromCraftable }