import React, { useContext, useState } from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import { Navigate } from 'react-router-dom';

import { Paths } from'../pages';
import { UserContext } from '../hooks/useUser';

function LoginPage() {
    const { authToken, loginUser } = useContext(UserContext);
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async e => {
        console.log("loggin in from loginpage");
        e.preventDefault();

        loginUser(username, password);
    };

    if (authToken) {
        return <Navigate to={Paths.root} replace={true} />;
    }

    return (
        <Container fluid data-bs-theme="dark" data-theme="dark" className="text-light" id="body">
            <div className="Auth-form-container">
                <form className="Auth-form" onSubmit={handleSubmit}>
                    <div className="Auth-form-content">
                        <Row className="justify-content-center">
                            <Col>
                                <div className="d-flex justify-content-center">
                                    <Image src={process.env.PUBLIC_URL + '/sc_logo.jpg'} style={{height: 169}} />
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center" >
                            <Col className="justify-content-center" xs={12} style={{width: 300}}>
                                <div className="form-group">
                                    <label>Username</label>
                                    <input
                                    type="text"
                                    className="form-control mt-1"
                                    placeholder="Enter username"
                                    onChange={e => setUserName(e.target.value)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center" >
                            <Col className="justify-content-center" xs={12} style={{width: 300}}>
                                <div className="form-group mt-3">
                                    <label>Password</label>
                                    <input
                                    type="password"
                                    className="form-control mt-1"
                                    placeholder="Enter password"
                                    onChange={e => setPassword(e.target.value)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col className="justify-content-center" xs={12} style={{width: 300}}>
                                <div className="d-grid gap-2 mt-3">
                                    <button type="submit" className="btn btn-primary">
                                    Login
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </form>
            </div>
        </Container>
    );
}

export { LoginPage };
