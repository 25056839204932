import React, { useState } from 'react';
import { Col, Container, Image, Row } from "react-bootstrap";
import { Navigate } from 'react-router-dom';
import { MigrationForm } from '../components/MigrationForm';
import Paths from './Paths';

function MigrationPage() {
    const [migrationSuccessful, setMigrationSuccessful] = useState(false);

    const onSuccessfulSubmission = () => {
        setMigrationSuccessful(true);
    };

    return migrationSuccessful ? <Navigate to={Paths.login} replace={true} /> : (
        <Container fluid data-bs-theme="dark" data-theme="dark" className="text-light" id="body">
            <Row className="justify-content-center">
                <Col className="d-flex justify-content-center">
                    <Image src={process.env.PUBLIC_URL + '/sc_logo.jpg'} style={{ height: 169 }} />
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col className="d-flex justify-content-center">
                    <MigrationForm onSuccess={onSuccessfulSubmission} />
                </Col>
            </Row>
        </Container>
    );
}

export { MigrationPage };
