import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import { useContext } from 'react';
import { Paths } from '../../pages';

import LoadingScreen from '../LoadingScreen';
import { DrinkUpServiceApiContext } from '../../DrinkUpServiceApiContext';

function CreateUserForm({ onSuccess }) {
    // Form Data
    const { drinkUpService } = useContext(DrinkUpServiceApiContext);
    const [ableToSubmit, setAbleToSubmit] = useState(true);
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [emailAddress, setEmailAddress] = useState();
    const [drinkUpPassword, setDrinkUpPassword] = useState();
    const [isCreatingUser, setCreatingUser] = useState(false);
    const [isCreationSuccessful, setIsCreationSuccessful] = useState(false);
    const [isCreationFailure, setIsCreationFailure] = useState(false);
    const [failureReason, setFailureReason] = useState(null);

    const validateForm = () => {

        if (!!firstName && !!lastName && !!emailAddress && !!drinkUpPassword) {
            
            if(firstName === "") {
                console.log("aa")
                setFailureReason("first name cannot be empty");
                setAbleToSubmit(false);
                return false;
            }

            if(lastName === "") {
                setAbleToSubmit(false);
                setFailureReason("last name cannot be empty");
                return false;
            }

            if(emailAddress === "") {
                setAbleToSubmit(false);
                setFailureReason("email address cannot be empty");
                return false;
            }

            if(drinkUpPassword.length < 8) {
                setAbleToSubmit(false);
                setFailureReason("Rumbustible Password must be 8 characters or longer");
                return false;
            }
            
            setFailureReason(null);
            setAbleToSubmit(true);
            return true;
        } else {
            setAbleToSubmit(false);
            setFailureReason("all fields need to be filled out");
            return false;
        }
    };

    const createUser = async ({ 
        emailAddress, firstName, lastName, drinkUpPassword
    }) => {
        try {
            const res = await drinkUpService.post(Paths.api.create, {
                // TODO Update User Details mapper to include email Address
                email: emailAddress,
                firstName: firstName,
                lastName: lastName,
                password: drinkUpPassword
            });
            console.log("create response:", res);

            const success = res.status === 201;
            if (success) {
                return success;
            } else {
                return false;
            }
        } catch (e) {
            console.error('failed to create user', e)
            return false;
        }
    };

    const onFormControlChngWrapper = (changefn) => (el) => {
        el.preventDefault();

        changefn(el.target.value);
        validateForm();
    }

    const onSubmit = async (el) => {
        el.preventDefault()

        console.log("email:" + emailAddress);

        if(validateForm() === false) {
            return;
        }

        if (!ableToSubmit) return;

        // Submit Form via POST
        const userToCreate = {
            firstName, lastName, emailAddress, drinkUpPassword
        };
        // set loading
        setCreatingUser(true);

        try {
            const resStatus = await createUser(userToCreate);

            console.log("createUser result: " + resStatus);
            setIsCreationSuccessful(resStatus);
            if(!resStatus) {
                setIsCreationFailure(true);
            }
        } catch(e) {
            setIsCreationFailure(true);
        } finally {
            setCreatingUser(false);
        }
    };

    if(isCreatingUser) {
        return (<>
            <Stack className='text-center'>
                <LoadingScreen />
            </Stack>
        </>);
    }

    if(isCreationSuccessful) {
        return (<>
            <Stack gap={3} className='text-center'>
                <h1>User creation successful!</h1>
                <p> Login to access your account</p>
                <Button variant="primary" onClick={onSuccess}>Go to Login</Button>
            </Stack>
        </>);
    }

    if(isCreationFailure) {
        return (
            <p>User creation failed, please contact an administrator for support.</p>
        );
    }

    return (
        <Form style={{ 'minWidth': '20rem' }} onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" onChange={onFormControlChngWrapper(setEmailAddress)} />
                <Form.Text className="text-muted">
                    This email will represent your login
                </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" placeholder="First Name" onChange={onFormControlChngWrapper(setFirstName)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" placeholder="Last Name" onChange={onFormControlChngWrapper(setLastName)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDrinkUpPw">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" onChange={onFormControlChngWrapper(setDrinkUpPassword)} />
            </Form.Group>
            {
                !!failureReason
                    ? <><p style={{ 'color': 'red' }}>{failureReason}</p></> : ""
            }
            <div>
                <Button variant="primary" className="ml-auto"
                    type="submit" onSubmit={onSubmit}>
                    Create Account
                </Button>
            </div>
        </Form >
    );
}

export { CreateUserForm };
