import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Col, Row } from "react-bootstrap";

export default function RumRequestModal({ closeModal, show, request, user, rum, respond}) {    

    if(!request || !user) {
        return (
            <></>
        )
    }

    console.log("request: " + JSON.stringify(request));

    function acceptRequest() {
        respond(rum.id, request.id, true, null);
    }

    function denyRequest() {
        const rejectionReason = document.getElementById("rejectionReason").value;
        respond(rum.id, request.id, false, rejectionReason);
    }

    function formattedDateTime(dateString) {
        const date = new Date(Date.parse(dateString));

        var ampm = "AM";

        var hours = date.getHours();
        if(hours > 12) {
            hours = hours - 12;
            ampm = "PM";
        } else if(hours === 0) {
            hours = 12;
        } else if(hours === 12) {
            ampm = "PM";
        }

        return date.toDateString() + ", " + hours + ":" + String(date.getMinutes()).padStart(2, '0') + " " + ampm;
    }

    return (
        <>
        <Modal show={show} onHide={closeModal} size="lg">
            <Modal.Header closeButton>
            <Modal.Title>{request != null ? request.bevager_name : ""}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12} sm={3} className="text-sm-end text-xs-start">
                    <label className="fw-bold">Rum</label>
                    </Col>
                    <Col xs={12} sm={8}>
                    {rum.bevager_name}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={3} className="text-sm-end text-xs-start">
                    <label className="fw-bold">Requested By</label>
                    </Col>
                    <Col xs={12} sm={8}>
                    {user.first_name} {user.last_name}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={3} className="text-sm-end text-xs-start">
                    <label className="fw-bold">Country</label>
                    </Col>
                    <Col xs={12} sm={8}>
                    {rum != null ? rum.origin_country : ""}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={12} sm={3} className="text-sm-end text-xs-start">
                    <label className="fw-bold">Price</label>
                    </Col>
                    <Col xs={12} sm={8}>
                    {rum != null ? rum.price : ""}
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={12} sm={3} className="text-sm-end text-xs-start">
                        <label className="fw-bold">Request Status</label>
                    </Col>
                    <Col xs={12} sm={8}>
                    {
                        request && "approver" in request
                        ? "Approved by " + request.approver 
                        : request && "requested_on" in request
                        ? "Requested " + formattedDateTime(request.requested_on)
                        : <Button style={{padding: 0}} variant="link" onClick={request}>Request</Button>
                    }
                    {
                        request && "is_gift" in request && request.is_gift
                        ? <><br />Gifted by {request.gift_giver}</> : ""
                    }
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={12} sm={3} className="text-sm-end text-xs-start">
                        <label className="fw-bold">Rejection reason:</label>
                    </Col>
                    <Col xs={12} sm={8}>
                        <textarea id="rejectionReason" rows="4" cols="25">
                        </textarea>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="danger" onClick={denyRequest}>
                    Deny Request
                </Button>
                <Button variant="primary" onClick={acceptRequest}>
                    Approve Request
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}
