import React from 'react';
import axios from 'axios';

import { DrinkUpServiceApiContext } from './DrinkUpServiceApiContext';

// API Service Client
const LOCAL_API_SERVICE_URL = "http://localhost:8000";
const PRODUCTION_API_SERVICE_URL = "https://young-cliffs-57944.herokuapp.com";

const baseURL = window.location.hostname === "localhost" ? LOCAL_API_SERVICE_URL : PRODUCTION_API_SERVICE_URL;
const client = axios.create({
    baseURL
});

function DrinkUpServiceApiProvider(props) {
    return (
        <DrinkUpServiceApiContext.Provider value={{ drinkUpService: client }}>
            {props.children}
        </DrinkUpServiceApiContext.Provider>
    )
}

export { DrinkUpServiceApiProvider };
