import React from 'react';
import { Col, Row } from "react-bootstrap";

import { RumListItem } from './RumListItem';

function RumList(props) {
    const { rums, onRumSelection } = props;

    const onRumClick = (rumIndex) => {
        onRumSelection(rumIndex);
        console.log(`selected rum at index, ${rumIndex}`);
    };

    return (
        <>
            <Row>
                <Col>
                    {rums.length} items found
                </Col>
            </Row>
            {rums.map(
                    (rum, i) => <RumListItem key={i} onRumSelection={() => onRumClick(i)} rum={rum} />
            )}
        </>
    );
}

export { RumList };