import React from 'react';
import { Col, Row } from 'react-bootstrap';

import './RumListItem.css';

function RumListItem(props) {
    const { onRumSelection, rum } = props;
    
    function formattedDateTime(dateString) {
        const date = new Date(Date.parse(dateString));

        var ampm = "AM";

        var hours = date.getHours();
        if(hours > 12) {
            hours = hours - 12;
            ampm = "PM";
        } else if(hours === 0) {
            hours = 12;
        } else if(hours === 12) {
            ampm = "PM";
        }

        return date.toDateString() + ", " + hours + ":" + String(date.getMinutes()).padStart(2, '0') + " " + ampm;
    }
        
    const requestColor = rum && "approver" in rum ? "lime" : rum && "requested_on" in rum ? "yellow" : "red"

    return (
        <div onClick={onRumSelection} style={{borderBottom: "1px solid white"}}>
            <Row>
                <Col className={rum.is_immortal ? "immortal" : "regular-rum"}>{rum.bevager_name}</Col>
            </Row>
            <Row>
                <Col>{rum.origin_country}</Col>
                <Col md="auto">${parseFloat(rum.price).toFixed(0)}</Col>
            </Row>
            <Row>
                <Col style={{color: rum.is_available ? "lime" : "red"}}>
                    {rum.is_available ? "Available" : "Not Available"}
                </Col>
            </Row>
            <Row>
                <Col style={{color: requestColor}}>
                    {
                        rum && "approver" in rum
                        ? " Approved by " + rum.approver + ", " + formattedDateTime(rum.requested_on)
                        : rum && "requested_on" in rum
                        ? " Requested " + formattedDateTime(rum.requested_on)
                        : " Not yet requested"
                    }
                </Col>
            </Row>
            {
                rum && "notes" in rum ? <Row><Col>{rum.notes}</Col></Row> : ""
            }
        </div>
    );
}

export { RumListItem };