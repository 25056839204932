import { Modal, Row, Col, Form } from "react-bootstrap";

function UserFilterModal(props) {

    const { closeModal, show, filters, onFiltersChanged } = props;

    const onFieldChanged = (e) => {
        filters.maxPrice = parseFloat(document.getElementById("maxPrice").value);
        filters.minPrice = parseFloat(document.getElementById("minPrice").value);
        filters.hideSigned = document.getElementById("hideSigned").checked;
        filters.hideRequested = document.getElementById("hideRequested").checked;
        filters.hideUnavailable = document.getElementById("hideUnavailable").checked;
        filters.useMaxPrice = document.getElementById("useMaxPrice").checked;
        filters.useMinPrice = document.getElementById("useMinPrice").checked;
        filters.separateSearchTerms = document.getElementById("separateSearchTerms").checked;

        onFiltersChanged(filters);
    }

    return (
        <>
        <Modal show={show} onHide={closeModal} size="lg">
            <Modal.Header closeButton>
            <Modal.Title>Filters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Row>
                <Col>
                    <Form.Check id="hideSigned" label="Hide Signed" checked={filters.hideSigned} onChange={onFieldChanged} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check id="hideRequested" label="Hide Requested" checked={filters.hideRequested}  onChange={onFieldChanged} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check id="hideUnavailable" label="Show Available Only" checked={filters.hideUnavailable}  onChange={onFieldChanged} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check id="separateSearchTerms" label="Separate search terms by space" checked={filters.separateSearchTerms}  onChange={onFieldChanged} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check id="useMaxPrice" label="Use Max Price" checked={filters.useMaxPrice} onChange={onFieldChanged} />
                </Col>
                <Col>
                    <Form.Control type="number" id="maxPrice" placeholder="Max Price" value={filters.maxPrice} onChange={onFieldChanged} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check id="useMinPrice" label="Use Min Price" checked={filters.useMinPrice} onChange={onFieldChanged} />
                </Col>
                <Col>
                    <Form.Control type="number" id="minPrice" placeholder="Min Price" value={filters.minPrice} onChange={onFieldChanged} />
                </Col>
            </Row>
            </Modal.Body>
            <Modal.Footer>
                
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default UserFilterModal;