import React, { useContext, useRef, useState } from 'react';
import { Container } from "react-bootstrap";
import { Navigate } from 'react-router-dom';
import Paths from './Paths';

import { DrinkUpServiceApiContext } from '../DrinkUpServiceApiContext';
import LoadingPage from '../components/LoadingScreen';
import { RumList } from '../components/RumList';
import UserRumModal from '../components/UserPage/UserRumModal';
import { MEMBER_USER, UserContext } from '../hooks/useUser';
import UserNav from '../components/UserPage/UserNav';
import UserProfileModal from '../components/UserPage/UserProfileModal';
import UserFilterModal from '../components/UserPage/UserFilterModal';
import UserSortModal from '../components/UserPage/UserSortModal';

export const SORT_SETTING = "SORT_SETTING";
export const FILTER_SETTINGS = "FILTER_SETTINGS";

export const sortOptions = {
    nameAsc: "Name (Ascending)",
    nameDesc: "Name (Descending)",
    priceAsc: "Price (Ascending)",
    priceDesc: "Price (Descending)",
    countryAsc: "Country (Ascending)",
    countryDesc: "Country (Descending)"
};

function UserPage() {

    const filterOptions = {
        hideSigned: true,
        hideRequested: true,
        hideUnavailable: true,
        minPrice: 0,
        useMinPrice: false,
        maxPrice: 1000,
        useMaxPrice: false,
        separateSearchTerms: true
    }

    const { drinkUpService } = useContext(DrinkUpServiceApiContext);
    const { authToken, userDetails } = useContext(UserContext);

    const [filteredRums, setFilteredRums] = useState([]);
    const [selectedRum, setSelectedRum] = useState(null);
    const [rumShown, setRumShown] = useState(false);
    const [profileShown, setProfileShown] = useState(false);
    const [sortModalShown, setSortModalShown] = useState(false);
    const [filterModalShown, setFilterModalShown] = useState(false);

    const sortOption = useRef(sortOptions.priceAsc);
    const rums = useRef([]);
    const isLoaded = useRef(false);
    const searchText = useRef("");
    const filters = useRef(filterOptions);

    const filterStorage = localStorage.getItem(FILTER_SETTINGS);
    if(!filterStorage) {
        localStorage.setItem(FILTER_SETTINGS, JSON.stringify(filters.current));
    } else {
        filters.current = JSON.parse(filterStorage);
    }

    const storedSort = localStorage.getItem(SORT_SETTING);
    if(!storedSort) {
        localStorage.setItem(SORT_SETTING, sortOption.current);
    } else {
        sortOption.current = storedSort;
    }

    if (!authToken) {
        console.log("no auth token user page");
        return <Navigate to={Paths.root} replace={true} />;
    }
    if (!userDetails) {
        console.log("user: no user details");
        return <Navigate to={Paths.root} replace={true} />;
    }
    if (userDetails.type !== MEMBER_USER) {
        console.log("not user type member");
        return <Navigate to={Paths.root} replace={true} />;
    }

    const compareRums = (a, b) => {
        const sorting = sortOption.current;

        if(sorting === sortOptions.countryAsc) {
            return a.origin_country < b.origin_country ? -1 : 1;
        } else if(sorting === sortOptions.countryDesc) {
            return a.origin_country > b.origin_country ? -1 : 1;
        } else if(sorting === sortOptions.nameAsc) {
            return a.bevager_name < b.bevager_name ? -1 : 1;
        } else if(sorting === sortOptions.nameDesc) {
            return a.bevager_name > b.bevager_name ? -1 : 1;
        } else if(sorting === sortOptions.priceAsc) {
            return parseInt(a.price) < parseInt(b.price) ? -1 : 1;
        } else if(sorting === sortOptions.priceDesc) {
            return parseInt(a.price) > parseInt(b.price) ? -1 : 1;
        }
    }

    function filterRum(rum) {

        if (filters.current.hideSigned) {
            if ("approver" in rum && rum.approver) {
                return false;
            }
        }

        if (filters.current.hideUnavailable === true) {
            if(rum.is_available === false) {
                return false;
            }
        }

        if (filters.current.hideRequested === true) {
            if(rum.requested_on) {
                return false;
            }
        }

        if (filters.current.useMinPrice) {
            if (!isNaN(filters.current.minPrice) && !isNaN(parseFloat(filters.current.minPrice))) {
                const priceCheck = parseFloat(filters.current.minPrice);

                if (rum.price < priceCheck) {
                    console.log("price too low");
                    return false;
                }
            }
        }

        if (filters.current.useMaxPrice) {
            if (!isNaN(filters.current.maxPrice) && !isNaN(parseFloat(filters.current.maxPrice))) {
                const priceCheck = parseFloat(filters.current.maxPrice);

                if (rum.price > priceCheck) {
                    console.log("price too high");
                    return false;
                }
            }
        }

        if(!searchText.current) {
            return true;
        }

        const terms = filters.current.separateSearchTerms ? searchText.current.split(" ") : [searchText.current];

        for(const term of terms) {
            if (rum.notes) {
                if(rum.notes.toUpperCase().includes(term.toUpperCase())) {
                    continue;
                }
            }

            if (rum.origin_country) {
                if(rum.origin_country.toUpperCase().includes(term.toUpperCase())) {
                    continue;
                }
            }

            if(rum.bevager_name.toUpperCase().includes(term.toUpperCase())) {
                continue;
            }

            return false;
        }  

        return true;
    }

    function filterRums() {
        console.log("filtering..." + rums.current.length);
        const rum2 = [...rums.current];
        const filtered = rum2.filter(filterRum);
        console.log("filtered count: " + filtered.length);
        setFilteredRums(filtered.sort(compareRums));
    }

    function loadRums() {
        if (isLoaded.current) {
            return;
        }
        isLoaded.current = true;
        console.log("loading rums");

        drinkUpService.get("/get_user_rums", {
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                console.log("new rums: " + response.data.length);
                rums.current = response.data;
                filterRums();
            })
            .catch(err => {
                console.log(err);
            });
    }

    const showRum = (rowNumber) => {
        console.log("Show rum: " + JSON.stringify(filteredRums[rowNumber]));
        setSelectedRum(filteredRums[rowNumber]);
        setRumShown(true);
    }

    function saveNotes(notes) {
        const body = {
            rum: selectedRum.id,
            notes: notes
        };
        console.log("saving notes: " + notes);
        drinkUpService.post("/save-notes", body, {
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then(() => {
                // get rum index
                const index = rums.current.findIndex(function (item, i) {
                    return item.id === selectedRum.id;
                });

                console.log("index of rum: " + index);
                rums.current[index]["notes"] = notes;
                setSelectedRum(rums.current[index]);
                filterRums();
            })
            .catch(err => {
                console.log(err);
                alert("An Error Occurred. Please try again. \n\n" + err);
            });
    }

    const requestRum = (rumId, isDelete, isGift, giftedBy) => {
        console.log("Requesting rum: " + rumId + '\ntoken: ' + authToken + "isDelte" + isDelete);
        const body = { 
            rum: rumId, 
            isDelete: isDelete,
            isGift: isGift,
            giftGiver: giftedBy
        };
        drinkUpService.post("/request-rum", body, {
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            // get rum index
            const index = rums.current.findIndex(function (item, i) {
                return item.id === rumId;
            });

            console.log("index of rum: " + index);

            if(isDelete) {
                delete rums.current[index]["requested_on"];
            } else {
                rums.current[index]["requested_on"] = response.data.requested_on;
            }

            console.log(JSON.stringify(rums.current[index]));

            setSelectedRum(rums.current[index]);//update the shown rum
            filterRums();            
        })
        .catch(err => {
            console.log(err);
            alert("An Error Occurred. Please try again. \n\n" + err);
        });
    }

    const closeModal = () => {
        if (rumShown) {
            setRumShown(false);
        }
        if (profileShown) {
            setProfileShown(false);
        }
        if (sortModalShown) {
            setSortModalShown(false);
        }
        if (filterModalShown) {
            setFilterModalShown(false);
        }
        console.log("close modal");
    }

    const showProfile = () => {
        setProfileShown(true);
    }

    const showFilterModal = () => {
        setFilterModalShown(true);
    }

    const showSortModal = () => {
        setSortModalShown(true);
    }

    const selectRandomRum = () => {
        const rumIndex = Math.floor(Math.random() * filteredRums.length);
        showRum(rumIndex);
    }

    const searchRums = (event) => {
        console.log("Search Text: " + event.target.value);
        searchText.current = event.target.value;
        filterRums()
    }

    const updateSorting = (event) => {
        closeModal();
        console.log("event" + JSON.stringify(event.target.value));
        sortOption.current = event.target.value;
        localStorage.setItem(SORT_SETTING, sortOption.current);
        setFilteredRums(filteredRums.sort(compareRums));
    }

    const updateFiltering = (newFilters) => {
        filters.current = newFilters;
        localStorage.setItem(FILTER_SETTINGS, JSON.stringify(filters.current));
        
        console.log("updated filters: " + JSON.stringify(filters.current));
        filterRums();
    }

    loadRums();

    if (rums.current.length === 0) {
        return <LoadingPage />
    }

    return (
        <Container fluid data-bs-theme="dark" data-theme="dark" className="text-light" id="body">
            <UserNav onProfileSelect={showProfile} onDiceSelect={selectRandomRum} onSortSelect={showSortModal} onFilterSelect={showFilterModal} onSearchChange={searchRums} />
            <RumList onRumSelection={showRum} rums={filteredRums} />
            <UserRumModal show={rumShown} closeModal={closeModal} rum={selectedRum} requestRum={requestRum} saveNotes={saveNotes} />
            <UserProfileModal show={profileShown} closeModal={closeModal} rums={rums.current} />
            <UserFilterModal show={filterModalShown} closeModal={closeModal} filters={filters.current} onFiltersChanged={updateFiltering} />
            <UserSortModal show={sortModalShown} closeModal={closeModal} sortOptions={sortOptions} currentSort={sortOption.current} onSortChange={updateSorting} />
        </Container>
    );
}

export { UserPage };
